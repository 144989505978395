<script lang="jsx">
import { configGetter } from '@store/config.js'
import './style.styl'
import Lang from '@components/lang/index.vue'

export default {
  name: 'warningMessage',
  props: {},
  computed: {
    config: configGetter.getConfig
  },
  data: () => ({
    isOpen: false,
    date: 0,
    disableMessage: localStorage.getItem('disableMessage')
  }),
  watch: {
    'config.maintenance'(newVal) {
      this.check()
    }
  },
  mounted() {
    this.date = new Date().getDate() - 0
    this.disableMessage = localStorage.getItem('disableMessage')
    this.check()
  },
  methods: {
    check() {
      console.info('check', this.config.maintenance, !this.isOpen)
      if (this.config.maintenance && !this.isOpen) {
        if (this.disableMessage != this.date) {
          this.isOpen = true
        }
      }
    },
    closeMessage() {
      this.isOpen = false
      localStorage.setItem('disableMessage', new Date().getDate() + '')
    }
  },
  render(createElement, context) {
    if (this.config?.maintenance && this.isOpen) {
      return (
        <div class='container'>
          <div class='main-info-message__wrapper'>
            <div class='main-info-message'>
              <svg
                class='main-info-message__icon'
                width='26'
                height='26'
                viewBox='0 0 26 26'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle cx='13' cy='13' r='13' fill='black' />
                <path
                  d='M12.764 9.51C12.19 9.51 11.7 9.034 11.7 8.446C11.7 7.858 12.19 7.396 12.764 7.396C13.352 7.396 13.828 7.858 13.828 8.446C13.828 9.034 13.352 9.51 12.764 9.51ZM13.66 17H11.882V10.238H13.66V17Z'
                  fill='white'
                />
              </svg>
              <Lang
                tag='p'
                class='main-info-message__title'
                name='main.maintenance.title'
              />
              <button
                class='main-info-message-close'
                onClick={this.closeMessage}
              >
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <g clip-path='url(#clip0_1596_1804)'>
                    <path
                      d='M0.735962 0.735596L7.90838 7.90801L0.735962 15.2643'
                      stroke='black'
                      stroke-miterlimit='10'
                    />
                    <path
                      d='M15.2643 0.735596L8.09192 7.90801L15.2643 15.2643'
                      stroke='black'
                      stroke-miterlimit='10'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1596_1804'>
                      <rect width='16' height='16' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )
    }
  }
}
</script>
