import { reactive } from 'vue'
import Cookies from 'js-cookie'
import { useRouter } from 'vue-router'

const lang = reactive({
  list: [
    { name: 'Рус', value: 'ru', search: '' },
    { name: 'Қаз', value: 'kz', search: 'kz' }
    // { name: 'Eng', value: 'en', search: 'en' }
  ],
  active: 'ru'
})
export const setActiveLang = value => {
  lang.active = value
  const params = new URLSearchParams(window.location.search)
  const langParam = getLangList().filter(i => i.value == value)[0].search
  if (langParam.length > 0) {
    params.set('lang', langParam)
  } else {
    params.delete('lang')
  }

  window.history.replaceState(
    {},
    '',
    decodeURIComponent(`${window.location.pathname}?${params}`)
  )
}

export const getPossibleLangs = () => lang.list.map(item => item.value)
export const checkActiveLang = () => {
  let { search } = location
  if (search.length > 0) {
    search = new URLSearchParams(search)
    if (
      typeof search.get('lang') == 'string' &&
      search.get('lang').length > 0
    ) {
      search = search.get('lang')
      if (getPossibleLangs().indexOf(search) >= 0) {
        setActiveLang(search)
      }
    }
  } else {
    search = Cookies.get('lang')
    if (getPossibleLangs().indexOf(search) >= 0) {
      setActiveLang(search)
    }
  }
}
export const getActiveLang = () => lang.active
export const getLangList = () => lang.list
