<script lang="jsx">
// console.info(host);
import '@css/style.styl'
import { userActions } from '@store/user'
import { configActions } from '@store/config.js'
import WarningMessage from '@components/warningMessage/index.vue'

export default {
  name: 'Wrapper',

  data: () => ({}),
  created() {},
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  },
  mounted() {
    setTimeout(() => {
      configActions.startInfiniteLoading()
    }, 500)
    userActions.checkToken()
  },
  render() {
    switch (this.$route.meta.type) {
      case 'main':
        return (
          <div class='wrapper'>
            <WarningMessage />
            <router-view name='banner' />
            <router-view name='header' />
            <router-view name='nominations' />
            <router-view name='projects' />
            <router-view name='system' />
            <router-view name='gallery' />
            <router-view name='nomineesMain' />
            <router-view name='partners' />
            <router-view name='footer' />
            <router-view name='popup' />
          </div>
        )
      case 'partners':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <router-view name='innerBanner' />
            <router-view name='partnersPage' />
            <router-view name='footer' />
          </div>
        )
      case 'photo-gallery':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <div class='page'>
              <div class='body'>
                <router-view name='topLine' />
                <router-view name='photogallery' />
              </div>
              <router-view name='footer' />
            </div>
          </div>
        )
      case 'jury':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <router-view name='innerBanner' />
            <router-view name='jury' />
            <router-view name='footer' />
          </div>
        )
      case 'registration':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <div class='page'>
              <div class='body'>
                <router-view name='topLine' />
                <router-view name='registration' />
              </div>
            </div>
            <router-view name='footer' />
          </div>
        )
      case 'nominees':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <div class='page'>
              <div class='body'>
                <router-view name='topLine' />
                <router-view name='nominees' />
              </div>
            </div>
            <router-view name='footer' />
          </div>
        )
      case 'account':
        return (
          <div class='wrapper inner-page'>
            <WarningMessage />
            <router-view name='header' />
            <div class='page'>
              <div class='body'>
                <router-view name='topLine' />
                <router-view name='account' />
              </div>
            </div>
            <router-view name='footer' />
          </div>
        )
      default:
        return null
    }
  }
}
</script>
