//getConfig
import { reactive } from 'vue'
import { getConfig } from '@api'

const store = reactive({
  config: {
    maintenance: false,
    phase: 1
  },
  infiniteId: false
})

export const configActions = {
  startInfiniteLoading() {
    if (!store.infiniteId) {
      getConfig().then(res => {
        store.config = res
      })
      store.infiniteId = setInterval(() => {
        getConfig().then(res => {
          store.config = res
        })
      }, 30 * 1000)
    }
  }
}
export const configGetter = {
  getConfig: () => store.config
}
